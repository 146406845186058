import React from 'react';
import styled from 'styled-components';
import { fluidRange } from 'polished';
import Waypoint from './Waypoint';

import Button from './Button';
import Grid from './Grid';
import { H2 } from './Typography';
import Marquee from './Marquee';
import Section from './Section';
import ReadyText from '../icons/ReadyWhenYouAre';

import { email } from '../../../config';
import { fadeIn } from '../../utils/mixins';

const Wrapper = styled(Section)`
  ${fluidRange(
    {
      prop: 'padding-top',
      fromSize: '40px',
      toSize: '80px',
    },
    '380px',
    '1400px'
  )};
  ${fadeIn()}
`;

const Spiel = styled.div`
  @media (max-width: 799px) {
    grid-column: span 12;
    text-align: center;
  }
  @media (min-width: 800px) {
    grid-column: span 8;
  }
`;

const Main = styled(Grid)`
  ${fluidRange(
    {
      prop: 'margin-top',
      fromSize: '40px',
      toSize: '60px',
    },
    '380px',
    '1400px'
  )};
`;

const PreFooter = () => (
  <Waypoint
    render={({ visible }) => (
      <Wrapper active={visible}>
        <Marquee width={300} speed={15}>
          <ReadyText />
        </Marquee>
        <Main>
          <Spiel>
            <H2>
              Let’s work together to create a more sustainable world today.
            </H2>
            <Button color="orange" href={`mailto:${email}`}>
              Email us
            </Button>
          </Spiel>
        </Main>
      </Wrapper>
    )}
  />
);

export default PreFooter;
