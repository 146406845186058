import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';

export default class WaypointSection extends Component {
  constructor() {
    super();
    this.state = { visible: false };
  }

  handleWaypointEnter = () => {
    this.setState({
      visible: true,
    });
  };

  handleWaypointLeave = () => {
    const { fireOnce } = this.props;
    if (!fireOnce) {
      this.setState({
        visible: false,
      });
    }
  };

  render() {
    const { bottomOffset, topOffset, render } = this.props;
    return (
      <Waypoint
        bottomOffset={bottomOffset}
        topOffset={topOffset}
        onEnter={this.handleWaypointEnter}
        onLeave={this.handleWaypointLeave}
      >
        {render(this.state)}
      </Waypoint>
    );
  }
}

WaypointSection.defaultProps = {
  fireOnce: true,
  bottomOffset: '33%',
};

WaypointSection.propTypes = {
  fireOnce: PropTypes.bool,
  render: PropTypes.func.isRequired,
  topOffset: PropTypes.string,
  bottomOffset: PropTypes.string,
};
