import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { marquee } from '../../utils/keyframes';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const MiddleWrapper = styled.div`
  animation: ${marquee} linear infinite ${({ speed }) => speed}s;
  display: grid;
  grid-template-columns: 1fr 2.5% 1fr 2.5%;
  @media (max-width: 600px) {
    width: ${({ width }) => width[0]}%;
  }
  @media (min-width: 600px) {
    width: ${({ width }) => width[1]}%;
  }
`;

const Inner = styled.div`
  &:nth-child(2) {
    grid-column: 3;
  }
`;

export default function Marquee({ className, children, width, speed = 10 }) {
  return (
    <Wrapper className={className}>
      <MiddleWrapper
        width={Array.isArray(width) ? width : [width, width]}
        speed={speed}
      >
        <Inner>{children}</Inner>
        <Inner>{children}</Inner>
      </MiddleWrapper>
    </Wrapper>
  );
}

Marquee.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  speed: PropTypes.number,
  width: PropTypes.any.isRequired,
};
