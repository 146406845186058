import React from 'react';
import PropTypes from 'prop-types';

const Arrow = ({ className }) => (
  <svg className={className} viewBox="0 0 16 14">
    <path
      d="M9.3.3L7.9 1.7 12.2 6H0v2h12.2l-4.3 4.3 1.4 1.4L16 7z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default Arrow;

Arrow.propTypes = {
  className: PropTypes.string,
};
