import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.span`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.orangeDark};
  width: 1em;
  height: 1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  vertical-align: 0.1875rem;
  will-change: transform;
  transform: scale(${({ active }) => (active ? 1 : 0)});
  transition: transform 0.5s cubic-bezier(0.26, 0.72, 0.63, 1.49)
    ${({ active }) => (active ? '0s' : '1s')};
`;

const Inner = styled.span`
  font-size: 0.75em;
`;

const CircularSpan = ({ active, children }) => (
  <Wrapper active={active}>
    <Inner>{children}</Inner>
  </Wrapper>
);

CircularSpan.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default CircularSpan;
